/* .image {
    width: 100%;
    height: 70vh;
    object-fit:fill;
} */

/* Styles for the Home component */
.slider {
    position: relative;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    height: 100%; /* Set your desired height */
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 100%;
    height: 100%;
    /* object-fit:inherit;  */
   transition: transform 0.6s ease; 
  
   flex-basis: calc(33.33% - 80px);
   background-color: #304D6D;
   color: aliceblue;
   padding: 1px;
   border-radius: 1px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   text-align: center;
  
  
  }
  
  /* Additional styles for the images */
  .image:nth-child(1) {
    animation: fadeInOut 0s infinite; /* Add a fade-in/fade-out animation */
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  };


  @media (max-width: 768px) {
    .image {
      height: 70vh; /* Adjust the height for smaller screens */
      position: sticky;
    }
  }

  