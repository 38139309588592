/* Styles for the Products component */

  .items {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-right: 35px;
    background: #304D6D;
  }
  
  .product{
    background-color: #1c2237;
    text-align: center;
    margin: 0;
    padding: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }
  /* Styles for each product cards */
  .prouct-li {
    flex-basis: calc(33.33% - -60px);
    background-color: #304D6D;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
   
  };


  
  h2 {
    font-size: 20px;
    margin-top: 10px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    color: white;
    margin-top: 10px;
  }
  
  /* Styles for product images */
  .product-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .product-images img {
    width: 90%; /* Adjust the width as needed */
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  

  /* Media query for screens smaller than 768px (adjust as needed) */
@media (max-width: 768px) {
    .product {
        width: 100%;
        max-width: 100%;
        position: fixed;
        /* margin: 10px; */
    }
    .prouct-li {
      flex-basis: calc(100% - 20px); /* 1 item per row with spacing on smaller screens */
    };
    
  }